import Env from "@utils/env"
import networkService from "services/network/network.service"
import { IUser } from "./users-api.interfaces"
async function addUnsignedUser(
  email: string,
  phoneNumber?: string
): Promise<Partial<IUser>> {
  const url = `${Env.get("REACT_APP_BASE_URL")}/ai/video/create-lead-user`

  const response = await networkService.axios
    .post(url, { email, phoneNumber })
    .catch((error) => {
      throw new Error(error.response.data.message)
    })
  return response.data
}

export default addUnsignedUser

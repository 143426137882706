import { Box } from "@components/customized"
import { IExampleCard } from "./interface"

const ExampleCard: React.FC<IExampleCard> = ({ icon, title, onClick }) => (
  <Box
    onClick={onClick}
    className="flex cursor-pointer items-center justify-center rounded-lg border bg-white p-4 shadow-lg transition-all hover:bg-gray-100"
  >
    <span className="text-lg">{icon}</span>
    <p className="ml-4 text-sm">{title}</p>
  </Box>
)

export default ExampleCard

import { Button, Form, Modal } from "@components/controllers"
import { Box } from "@components/customized"
import { Input } from "antd"
import React, { useState } from "react"

interface PopupProps {
  onSubmit: (email: string, phoneNumber?: string) => void
  onClose: () => void
}

const Popup: React.FC<PopupProps> = ({ onSubmit, onClose }) => {
  const [email, setEmail] = useState<string>("")
  const [phoneNumber, setPhoneNumber] = useState<string | undefined>(undefined)
  const [error, setError] = useState<string | null>(null)

  const handleSubmit = () => {
    if (!email.trim()) {
      setError("Email is required.")
      return
    }
    setError(null)
    onSubmit(email, phoneNumber)
  }

  return (
    <Modal
      title="Enter Your Details"
      open={true}
      onCancel={onClose}
      footer={null}
    >
      <Form layout="vertical" onFinish={handleSubmit}>
        <Form.Item label="Email" required>
          <Input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter your email"
            size="large"
          />
        </Form.Item>
        <Form.Item label="Phone Number (Optional)">
          <Input
            type="tel"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            placeholder="Enter your phone number"
            size="large"
          />
        </Form.Item>
        {error && <p style={{ color: "red" }}>{error}</p>}
        <Form.Item>
          <Box style={{ display: "flex", justifyContent: "space-between" }}>
            <Button type="submit" variant="primary">
              Submit
            </Button>
            <Button variant="secondary" onClick={onClose}>
              Cancel
            </Button>
          </Box>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default Popup

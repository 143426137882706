import { Link, useLocation } from "react-router-dom"
import "./LandingPage.css"
import useGetAIVideo from "./hooks/useGetAIVideo.hook"
import { Loader } from "@components/customized"
import { routes } from "@router/router"

const LandingPage = () => {
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const videoId = searchParams.get("videoId")
  const { video, loading, error } = useGetAIVideo(Number(videoId))

  if (loading) return <Loader />
  if (error) return <div>{error}</div>

  return (
    <div className="landing-page">
      <header className="header">
        <h1 className="header-title">Welcome to Letstok</h1>
        <p className="header-subtitle">
          Your go-to tool for generating amazing video ads!
        </p>
      </header>
      <section className="hero-section">
        <div className="video-card">
          <video autoPlay loop controls className="hero-video">
            <source src={video?.publicUrl} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
        <div className="cta-button">
          <Link to={routes.landingAiGenerator}>
            <button className="cta">Generate more videos!</button>
          </Link>
        </div>
      </section>

      <footer className="footer">
        <p>© 2024 Letstok. All rights reserved.</p>
      </footer>
    </div>
  )
}

export default LandingPage

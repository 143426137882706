import { Box, Card, Screen } from "@components/customized"
import { ChangeEvent, useCallback, useEffect, useState } from "react"
import { Icons } from "@components/customized"
import TextareaAutosize from "react-textarea-autosize"
import ExampleCard from "./example-card"
import { useNavigate } from "react-router-dom"
import { routes } from "@router/router"
import generateUsingText from "services/api/REST/videos/text-generator.service"
import addUnsignedUser from "services/api/REST/users/add-unsigned-user.service"
import userStore from "@stores/user/user.store"
import Popup from "./popup"
import { Modal } from "components/controllers"
import { Button } from "antd"

type InputChangeEvent = ChangeEvent<HTMLTextAreaElement>

const TextGeneratorScreen = () => {
  useEffect(() => {
    const originalTitle = document.title
    document.title = "Letstok AI video generator"

    return () => {
      document.title = originalTitle
    }
  }, [])

  const navigate = useNavigate()
  const [inputText, setInputText] = useState<string>("")
  const [isMultiLine, setIsMultiLine] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [showPopup, setShowPopup] = useState<boolean>(false)
  const [showProcessingModal, setShowProcessingModal] = useState<boolean>(false)

  const cards = [
    {
      icon: "📣",
      title: "Promotion of a Sale or App",
      text: "Create a video promoting the PowerQuest Gaming App, featuring incentives such as 50% off in-game purchases for new players and emphasizing urgency with limited-time rewards available only this weekend. Highlight key features like multiplayer modes, daily challenges, and immersive graphics, and target casual gamers and mobile gaming enthusiasts. Prompt viewers to download PowerQuest now and join the adventure.",
    },
    {
      icon: "💼",
      title: "Business Showcase",
      text: "Create a video showcasing PhoneHub, an e-commerce store specializing in the latest smartphones, accessories, and tech gadgets. Highlight our key offerings, including top brands like Apple, Samsung, and Google, and target tech enthusiasts and customers seeking the best deals on mobile devices. Emphasize unique selling points such as competitive pricing, free shipping, and extended warranty options, and encourage viewers to shop the latest models at PhoneHub today.",
    },
    {
      icon: "📋",
      title: "Customer Testimonial or Case Study",
      text: "Develop a testimonial video featuring SafeDrive Insurance, outlining their background in affordable auto coverage, the challenges customers faced with high premiums and limited coverage options, and how SafeDrive's services provided better rates and flexible plans that save customers an average of $400 annually. Conclude with a call to action encouraging viewers to get a personalized car insurance quote today.",
    },
    {
      icon: "🛒",
      title: "New Product or Service Launch",
      text: "Generate a launch video for Letstok AI Video Generator, focusing on key features like automatic text-to-video creation in under 2 minutes and customizable UGC video templates. Address customer pain points such as high video production costs and long turnaround times, and target digital marketers, social media managers, and e-commerce businesses. Include launch incentives like a free trial for new users, and end with a call to action for viewers to try Letstok AI Video Generator now.",
    },
  ]

  const handleInputChange = (event: InputChangeEvent) => {
    setInputText(event.target.value)
  }

  const handleHeightChange = (height: number) => {
    setIsMultiLine(height > 40)
  }

  const handleCardClick = (text: string) => {
    setInputText(text)
    setErrorMessage(null)
  }

  const handleSubmit = useCallback(async () => {
    const userId = userStore.loggedIn
      ? userStore.user?.userId
      : localStorage.getItem("userId")

    if (!inputText.trim()) return

    if (userId) {
      await sendTextToGenerateVideo(userId, !userStore.loggedIn)
    } else {
      setShowPopup(true)
    }
  }, [inputText])

  const sendTextToGenerateVideo = async (userId: string, isLead: boolean) => {
    setIsLoading(true)
    setErrorMessage(null)
    try {
      await generateUsingText(inputText, userId, isLead)
      if (userStore.loggedIn) {
        navigate(routes.myVideos)
      } else {
        setShowProcessingModal(true)
        setInputText("")
      }
    } catch (error: any) {
      console.error(error)
      setErrorMessage(error.message)
    } finally {
      setIsLoading(false)
    }
  }

  const handlePopupSubmit = async (email: string, phoneNumber?: string) => {
    try {
      const userEntity = await addUnsignedUser(email, phoneNumber)
      if (userEntity.email && userEntity.userId) {
        localStorage.setItem("userId", userEntity.userId)
      } else throw new Error("Failed to create lead user.")
      setShowPopup(false)
    } catch (error: any) {
      setErrorMessage("Failed to create lead user.")
      console.error(error)
    }
  }

  return (
    <Screen
      title="Text Generator"
      subTitle="Enter text to generate a video"
      disableBreadcrumbs={!userStore.loggedIn}
    >
      <Card className="mt-10 flex w-full max-w-[800px] flex-col items-center justify-center self-center">
        <Icons.AILogo style={{ width: "100px" }} />
        <Box className="mb-16 mt-4 grid gap-4 p-8 md:grid-cols-4">
          {cards.map((card, index) => (
            <ExampleCard
              key={index}
              icon={card.icon}
              title={card.title}
              onClick={() => handleCardClick(card.text)}
            />
          ))}
        </Box>
        <Box
          className={`flex w-full items-center bg-gray-100 p-2 shadow-sm ${isMultiLine ? "rounded-3xl" : "rounded-full"}`}
        >
          <TextareaAutosize
            placeholder="Type your text here..."
            value={inputText}
            onChange={handleInputChange}
            onHeightChange={handleHeightChange}
            className={`flex-1 resize-none border-none bg-gray-100 px-4 py-2 focus:outline-none ${isMultiLine ? "overflow-auto rounded-3xl" : "overflow-hidden rounded-full"}`}
            minRows={1}
            maxRows={5}
          />
          <button
            onClick={handleSubmit}
            disabled={!inputText.trim() || isLoading}
            className={`ml-2 flex h-10 w-10 items-center justify-center rounded-full bg-blue-500 p-2 text-white ${!inputText.trim() || isLoading ? "cursor-not-allowed bg-gray-300" : "hover:bg-blue-600"}`}
          >
            {isLoading ? <Icons.Loading size={20} /> : <Icons.Send size={20} />}
          </button>
        </Box>
        {errorMessage && (
          <Box className="mt-2 text-red-500">{errorMessage}</Box>
        )}
        {showPopup && (
          <Popup
            onSubmit={handlePopupSubmit}
            onClose={() => setShowPopup(false)}
          />
        )}

        {showProcessingModal && (
          <Modal
            title="Video Processing"
            open={showProcessingModal}
            closable={false}
            footer={[
              <Button onClick={() => setShowProcessingModal(false)}>
                Close
              </Button>,
            ]}
          >
            <p>
              Your video is being processed. The result will be sent to your
              email once it's ready.
            </p>
          </Modal>
        )}
      </Card>
      <Box className="mt-20 text-center">
        <p className="text-xl font-bold">
          Ready to Unlock Letstok's Full Potential?
        </p>
        <p>
          Click{" "}
          <a
            href="https://www.letstok.com/contact"
            className="text-blue-500 underline"
          >
            here
          </a>{" "}
          to explore our subscription packages and find the plan that suits your
          needs.
        </p>
        <p>
          Want a personalized walkthrough?{" "}
          <a
            href="https://www.letstok.com/our-platform"
            className="text-blue-500 underline"
          >
            Book a demo
          </a>{" "}
          with our team to see how Letstok can elevate your video marketing.
        </p>
      </Box>
    </Screen>
  )
}

export default TextGeneratorScreen
